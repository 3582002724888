import React from "react";
import styles from "../../styles/about.module.sass";
import Block from "../Block";
import Building from "../../images/aboutUs/silne-zazemi.jpg";
import VIG from "../../images/aboutUs/about-vig-logo.svg";
import Title from "../Title";

const BackgroundOfTheCompany = () => (
    <Block>
        <div className={styles.backgroundOfTheCompany}>
            <div className={styles.backgroundOfTheCompanyContent}>
                <div className={styles.backgroundOfTheCompanyContentTop}>
                    <div>
                        <Title type={"h2"} color={"gold"} mb={12} mt={30}>
                            MŮŽEME SE OPŘÍT O SILNÉ ZÁZEMÍ
                        </Title>
                        <p>
                            Společnost KAPITOL patří do významné finanční
                            skupiny Vienna Insurance Group (VIG), do které patří
                            také nejvýznamnější společnosti rakouského a českého
                            trhu – Wiener Städtische a Kooperativa.
                        </p>
                    </div>
                    <div>
                        <img src={VIG} alt="" />
                    </div>
                </div>

                <div>
                    <ul>
                        <li>
                            Největší pojišťovací skupina ve&nbsp;střední
                            a&nbsp;východní Evropě.
                        </li>
                        <li>
                            Vznikla z&nbsp;iniciativy rakouské pojišťovny Wiener
                            Städtische.
                        </li>
                        <li>
                            Celkem (včetně Rakouska) sdružuje 50 společností
                            ve&nbsp;24&nbsp;zemích.
                        </li>
                        <li>
                            Finanční síla Vienna Insurace Group byla ohodnocena
                            mezinárodní ratingovou agenturou Standard and Poor´s
                            vynikajícím stupněm A+ se&nbsp;stabilním výhledem.
                        </li>
                        <li>
                            Akcie VIG jsou obchodovány na&nbsp;vídeňské
                            a&nbsp;pražské burze, kde patří ke&nbsp;spolehlivým
                            titulům.
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.backgroundOfTheCompanyImage}>
                <img src={Building} alt="" />
            </div>
        </div>
    </Block>
);

export default BackgroundOfTheCompany;
