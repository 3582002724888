import React from "react";
import styles from "../../styles/about.module.sass";
import { Link } from "gatsby";

const services = [
    {
        name: "Komplexní finanční poradenství",
        text:
            "Známe podmínky bank a pojišťoven, dokážeme je porovnat a připravíme vám nabídku nejzajímavějších pojistek, hypoték nebo investic, abyste se mohli dobře rozhodnout.",
        link: {
            slug: "https://www.kapitol.cz/",
            name: "www.kapitol.cz",
        },
    },
    {
        name: "KAPITOL Reality",
        text:
            "Prodáváte byt nebo dům? Hledáte nájemníky? Rádi vám pomůžeme ve všech segmentech realitního trhu, včetně právního a finančního servisu.",
        link: {
            slug: "https://www.kapitolreality.cz/",
            name: "www.kapitolreality.cz",
        },
    },
    {
        name: "KAPITOL Autopojištění",
        text:
            "Pro prodejce nových a ojetých vozidel nabízíme komplexní řešení v oblasti autopojištění pro jejich zákazníky.",
        link: {
            slug: "https://www.kapitolautopojisteni.cz/",
            name: "www.kapitolautopojisteni.cz",
        },
    },
];

const Services = () => (
    <div className={styles.services}>
        {services.map(service => (
            <div key={service.name}>
                <div className={styles.servicesName}>{service.name}</div>
                <div className={styles.servicesText}>{service.text}</div>
                {service.link && (
                    <div className={styles.servicesLink}>
                        <Link href={service.link.slug} target={"_blank"}>
                            {service.link.name}
                            <img src={require("../../images/arrow-next.svg")} />
                        </Link>
                    </div>
                )}
            </div>
        ))}
    </div>
);

export default Services;
