import React from "react";
import SiteLayout from "../components/SiteLayout";

import Title from "../components/Title/";
import Block from "../components/Block/";
import styles from "../styles/about.module.sass";
import Seo from "../components/Seo";
import Breadcrumb from "../components/BreadCrumb";
import {
    BackgroundOfTheCompany,
    Career,
    Management,
    Services,
} from "../components/About";

const AboutText = () => (
    <div className={styles.aboutText}>
        <div>
            <p className={styles.aboutTextPerex}>
                Jsme česká společnost s bohatou historií od roku 1995 a patříme
                do významné finanční skupiny Vienna Insurance Group (VIG).
            </p>
            <p>
                Během své existence jsme si vybudovali široké zázemí a díky
                zkušenostem i kvalitním partnerům patříme mezi hlavní
                představitele na trhu finančního poradenství. Naším cílem je
                spokojenost a důvěra našich klientů, což jsou základní
                předpoklady k dlouhodobé a vzájemně prospěšné spolupráci.
            </p>
            <p>
                Pomáháme vám dělat dobrá rozhodnutí ve všech oblastech, která
                máme rozdělena na jednotlivé divize poradenství a služeb:
            </p>
        </div>
        <div>
            <img
                src={require("../images/aboutUs/logo_light_2-1-768x512.jpg")}
                alt="Kapitol"
            />
        </div>
    </div>
);

export default class ONas extends React.Component {
    render() {
        return (
            <SiteLayout location={this.props.location}>
                <Seo title={"Kontakt na poradce pro investiční kovy"} />

                <Breadcrumb
                    leftIndent={false}
                    crumbs={[{ label: "O nás" }]}
                    mb={10}
                />
                <Block>
                    <Title type={"h1"} color={"gold"} mb={40}>
                        O nás
                    </Title>
                </Block>

                <Block grey>
                    <AboutText />

                    <Services />

                    <Management />
                    <br />
                    <br />
                    <br />
                </Block>

                <BackgroundOfTheCompany />

                <Career />
            </SiteLayout>
        );
    }
}
