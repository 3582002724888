import React from "react";
import styles from "../../styles/about.module.sass";
import { Link } from "gatsby";
import Block from "../Block";
import Image from "../../images/kariera-blok.jpeg";
import Title from "../Title";
import button from "../Button/button.module.sass";

const Career = props => (
    <Block darkBlue>
        <section className={styles.career}>
            <div>
                <img src={Image} alt="" />
            </div>

            <div>
                <Title type={"h1"} mb={20}>
                    MÁTE ZÁJEM S NÁMI SPOLUPRACOVAT?
                </Title>
                <p>
                    <strong>
                        Díky stabilitě zázemí, letitým zkušenostem, rozsáhlým
                        investicím do modernizaci systémů a především díky půl
                        milionu spokojených klientů máme co nabídnout
                        začínajícím poradcům i zkušeným obchodníkům. Přesvědčte
                        se sami.
                    </strong>
                </p>
                <br />
                <Link
                    to="/kariera-poradce-pro-investicni-kovy"
                    className={button.buttonGold}
                >
                    Kariéra
                </Link>
            </div>
        </section>
    </Block>
);

Career.propTypes = {};

export default Career;
