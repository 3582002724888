import React from "react";
import Title from "../Title";
import styles from "../../styles/about.module.sass";

const board = [
    {
        image: require("../../images/aboutUs/board/michal-pobuda.jpg"),
        name: "Ing. Michal Pobuda",
        position: "Předseda představenstva KAPITOLu",
    },
    {
        image: require("../../images/aboutUs/board/radek-milion.jpg"),
        name: "Ing. Radek Milion",
        position: "Člen představenstva KAPITOLu",
    },
];

const supervisors = [
    {
        name: "Jiří Sýkora",
        position: "Předseda dozorčí rady KAPITOLu.",
    },
    {
        name: "Mgr. Filip Král",
        position: "Člen dozorčí rady KAPITOLu.",
    },
    {
        name: "JUDr. Hana Machačová",
        position: "Členka dozorčí rady KAPITOLu.",
    },
    {
        name: "Ing. Marek Blaha",
        position: "Člen dozorčí rady KAPITOLu.",
    },
    {
        name: "Ing. Pavel Wiesner",
        position: "Člen dozorčí rady KAPITOLu.",
    },
];

const BoardPerson = ({ image, name, position }) => (
    <div className={styles.managementPerson}>
        {image && (
            <div>
                <img src={image} alt={name} />
            </div>
        )}
        <div>
            <h4>{name}</h4>
            <p>{position}</p>
        </div>
    </div>
);

const Management = () => (
    <div className={styles.management}>
        <Title type="h2" mb={10}>
            MANAGEMENT KAPITOLU
        </Title>

        <div className={styles.managementMainBoxWrapper}>
            <div className={styles.managementPersonBoard}>
                <Title type="h3" mb={15} mt={8}>
                    PŘEDSTAVENSTVO
                </Title>
                <div>
                    {board.map((person, index) => (
                        <BoardPerson {...person} key={index} />
                    ))}
                </div>
            </div>

            <div className={styles.managementPersonNormal}>
                <Title type="h3" mb={15} mt={8}>
                    DOZORČÍ RADA
                </Title>
                <div>
                    {supervisors.map((person, index) => (
                        <BoardPerson {...person} key={index} />
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default Management;
